var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-user-detail", attrs: { fluid: "" } },
    [
      _c(
        _setup.ValidationObserver,
        { ref: "obs" },
        [
          _c(
            "v-form",
            [
              _c(
                "v-row",
                [_c("v-col", [_c("h1", [_vm._v("Rapportage bewerken")])])],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "form-group", attrs: { cols: "6" } },
                    [
                      _c(_setup.ValidationProvider, {
                        attrs: { name: "Naam", rules: "required" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    "hide-details": "auto",
                                    label: "Naam",
                                    placeholder: "Naam",
                                    "persistent-placeholder": "",
                                    outlined: "",
                                    "error-messages": errors,
                                  },
                                  model: {
                                    value: _setup.reportData.name,
                                    callback: function ($$v) {
                                      _vm.$set(_setup.reportData, "name", $$v)
                                    },
                                    expression: "reportData.name",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "form-group", attrs: { cols: "3" } },
                    [
                      _c(_setup.ValidationProvider, {
                        attrs: {
                          name: "Datum",
                          rules: "",
                          mode: _setup.checkDateValid,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c(_setup.DateInput, {
                                  attrs: {
                                    label: "Rapport datum",
                                    "persistent-placeholder": "",
                                    errors: errors,
                                    outlined: "",
                                  },
                                  model: {
                                    value: _setup.reportData.reportDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _setup.reportData,
                                        "reportDate",
                                        $$v
                                      )
                                    },
                                    expression: "reportData.reportDate",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "form-group", attrs: { cols: "3" } },
                    [
                      _c(_setup.ValidationProvider, {
                        attrs: { name: "Volgorde", rules: "between:1,99" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    "hide-details": "auto",
                                    label: "Volgorde",
                                    placeholder: "Volgorde",
                                    "persistent-placeholder": "",
                                    outlined: "",
                                    "error-messages": errors,
                                    type: "number",
                                  },
                                  model: {
                                    value: _setup.reportData.order,
                                    callback: function ($$v) {
                                      _vm.$set(_setup.reportData, "order", $$v)
                                    },
                                    expression: "reportData.order",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "form-group", attrs: { cols: "6" } },
                    [
                      _c(_setup.ValidationProvider, {
                        attrs: { name: "Workspace Id", rules: "required|guid" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    "hide-details": "auto",
                                    label: "Workspace Id",
                                    placeholder: "Workspace Id",
                                    "persistent-placeholder": "",
                                    outlined: "",
                                    "error-messages": errors,
                                  },
                                  model: {
                                    value: _setup.reportData.workspaceId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _setup.reportData,
                                        "workspaceId",
                                        $$v
                                      )
                                    },
                                    expression: "reportData.workspaceId",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "form-group", attrs: { cols: "6" } },
                    [
                      _c(_setup.ValidationProvider, {
                        attrs: { name: "Report Id", rules: "required|guid" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    "hide-details": "auto",
                                    label: "Report Id",
                                    placeholder: "Report Id",
                                    "persistent-placeholder": "",
                                    outlined: "",
                                    "error-messages": errors,
                                  },
                                  model: {
                                    value: _setup.reportData.reportId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _setup.reportData,
                                        "reportId",
                                        $$v
                                      )
                                    },
                                    expression: "reportData.reportId",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "form-group", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "secondary",
                          attrs: { dark: "", type: "button", title: "Testen" },
                          on: { click: _setup.testEmbedded },
                        },
                        [_vm._v(" Testen ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "form-group", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          attrs: {
                            dark: "",
                            title: "Opslaan",
                            disabled: _setup.isSaving,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _setup.onSave()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "", left: "" } }, [
                            _vm._v("mdi-checkbox-marked-circle"),
                          ]),
                          _vm._v(" Opslaan "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "form-group", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          attrs: {
                            dark: "",
                            title: "Annuleren",
                            disabled: _setup.isSaving,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _setup.onCancel()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "", left: "" } }, [
                            _vm._v("mdi-cancel"),
                          ]),
                          _vm._v(" Annuleren "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  !_setup.isNew
                    ? _c(
                        "v-col",
                        { staticClass: "form-group", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "secondary",
                              attrs: {
                                dark: "",
                                title: "Verwijderen",
                                disabled: _setup.isSaving,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _setup.onDelete()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                                _vm._v("mdi-delete"),
                              ]),
                              _vm._v(" Verwijderen "),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _setup.showTest
                        ? _c("powerbi-viewer", {
                            staticClass: "dropzone",
                            attrs: {
                              workspaceId: _setup.testWorkspaceId,
                              reportId: _setup.testReportId,
                              componentKey: _setup.componentKey,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }